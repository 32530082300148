<template>
    <v-card class="error mb-7 w-100">
        <v-card-text class="pa-7">
            <div class="d-flex">
                <div class="stats">
                    <h1 class="white--text font-weight-regular">{{ tagLike }}+</h1>
                    <h6 class="white--text body-1 mt-3">Me Gusta</h6>
                    <v-btn depressed rounded class="mt-4 body-1 text-capitalize">
                        Chequear lista
                    </v-btn>
                </div>
                <div class="stats-icon text-right ml-auto">
                    <v-icon large class="display-5 white--text">mdi-heart-outline</v-icon>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import dashBoardService from '@/providers/DashboardService';
    
    export default {
        name: "TheFollowerCardB",
        data: () => ({
            tagLike : 0
        }),
        methods: {
            getCountTagMark() {
                dashBoardService.getCountTags('like').then(fechData => {
                    this.tagLike = fechData;
                });
            }
        },
        mounted() {
            this.tagLike = 0; // 6509;
            this.getCountTagMark();
        }
    };
</script>

<style>
    .theme--light.v-card.fbcolor, .theme--dark.v-card.fbcolor {
        background-color: #5158d8 !important;
    }
</style>